<template>
  <RouterView></RouterView>
</template>

<script>
export default {
  name: 'Dashboard',
}
</script>

<style scoped>

</style>
